<script setup>
const { $store, $router } = useNuxtApp();
const { slug } = useRoute().params;
const config = useRuntimeConfig();
const storyblokStore = useStoryblokStore();
const { locale } = useI18n();
const resolveRelations = [];

let url = slug && slug.length > 0 ? slug.join("/") : "home";

// urlWithoutLangPath
url = url.replace(/\/$/, "");
let story = null;

if (!url.includes("m-edia/") && !url.includes("trash/")) {
  try {
    story = await useAsyncStoryblok(
      url,
      {
        ...config.public.storyblok.apiOptions,
        language: locale.value,
        resolve_relations: resolveRelations,
      },
      {
        resolveRelations,
      },
    );
    story = unref(story);

    if (
      locale.value !== "de" &&
      story &&
      !story.translated_slugs?.find((slug) => slug.lang === locale.value)?.name
    ) {
      throw createError({
        statusCode: 404,
        statusMessage: "Page Not Found",
        fatal: true,
      });
    }
    if (!story) {
      let isTranslatedSlug = false;

      Object.keys(storyblokStore.links).find((key) => {
        const link = storyblokStore.links[key];

        if (
          link.alternates.find((alternate) => alternate.translated_slug === url)
        ) {
          isTranslatedSlug = true;
          url = link.slug;
          return true;
        }
        if (isTranslatedSlug) {
          return true;
        }
      });

      // Find the story with the translated slug as fallback
      if (isTranslatedSlug) {
        story = await useAsyncStoryblok(
          url,
          {
            ...config.public.storyblok.apiOptions,
            language: locale.value,
            resolve_relations: resolveRelations,
          },
          {
            resolveRelations,
          },
        );
      }

      story = unref(story);
    }

    // Bugfix: redirect https://zweikern.com/ to correct language if story is not found
    if (!story && locale.value !== "de") {
      $router.push(`${locale.value}/home`);
    }

    if (story?.status) {
      throw createError({
        statusCode: story.status,
        statusMessage: story.response,
        fatal: true,
      });
    }

    if (story) {
      $store.dispatch("setStory", story);
      story.content.og_image = story.content?.og_image?.filename
        ? story.content?.og_image
        : story.content?.image;

      useMyHead(story);
    }
  } catch (error) {
    if (typeof error === "string") {
      const e = destr(error);
      throw createError({
        statusCode: e?.status || 500,
        statusMessage: e?.response,
      });
    }

    throw createError({
      statusCode: error?.status || 500,
      statusMessage: error?.response,
    });
  }
}

// if (!story) {
//   showError({ statusCode: 404, statusMessage: "Page Not Found" });
// }

const animateCSSElements = (prefix = "animate__") => {
  // Get all elements with data-animate attribute
  const nodes = document.querySelectorAll("[data-animate]");

  nodes.forEach((node) => {
    const animation = node.getAttribute("data-animate");
    const delay = node.getAttribute("data-animate-delay") || 0; // Default delay is 0
    const duration = node.getAttribute("data-animate-duration") || 8; // Default delay is 0
    const elementVisible = node.getAttribute("data-animate-visible") || 50; // Default delay is 150
    const repeat = node.getAttribute("data-animate-repeat") || 0; // Default delay is 0

    const windowHeight = window.innerHeight;
    const elementTop = node.getBoundingClientRect().top;

    // Add the animation classes directly
    const animationName = `${prefix}${animation}`;

    // Initially hide the element
    if (!node.classList.contains("animate__animated")) {
      node.classList.add("hidden");
    }

    if (elementTop < windowHeight - elementVisible) {
      node.classList.remove("hidden");
      node.classList.add("animated", `${prefix}animated`, animationName);

      if (delay > 0) {
        node.classList.add(`d${delay}`);
      }
      if (duration > 0) {
        node.classList.add(`a${duration}`);
      }
    } else {
    }

    // When the animation ends, we clean the classes
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(
        `${prefix}animated`,
        animationName,
        `d${delay}`,
        `a${duration}`,
      );
    }

    if (repeat > 0) {
      node.addEventListener("animationend", handleAnimationEnd, { once: true });
    }
  });
};

const { replaceHeadlineGradient } = useUtils();
// let initAnimateCSSElementsInterval = null;
// onBeforeMount(() => {
//   initAnimateCSSElementsInterval = setInterval(
//     () => initAnimateCSSElements(),
//     100,
//   );
// });
const hidden = ref(true);
onMounted(() => {
  replaceHeadlineGradient();

  // Fix for the headline gradient not showing up on page load
  setTimeout(() => {
    replaceHeadlineGradient();
  }, 1000);

  hidden.value = false;
  animateCSSElements();
  window.addEventListener("scroll", () => animateCSSElements());
});

onUnmounted(() => {
  replaceHeadlineGradient();
  animateCSSElements();
  window.removeEventListener("scroll", () => animateCSSElements());
});
</script>

<template>
  <BgHomeGradient v-if="story?.content?.layout === 'hero'" />

  <StoryblokComponent
    v-if="story?.content"
    :blok="story.content"
    :story="story"
  />

  <!-- <ScrollTop v-if="story" :show="showScrollToTopButton" /> -->
</template>
