import { useScrollTop } from "./useScrollTop";

export const useMyHead = (story: Object) => {
  const { locale } = useI18n();
  const runtimeConfig = useState("runtimeConfig");
  const config = runtimeConfig.value;
  const utils = useUtils();
  const localePath = useLocalePath();

  const title =
    story.content?.metatags?.title || story?.name || story.content?.title;
  const description = story.content?.metatags?.description;

  useSeoMeta({
    title,
    ogTitle: title,
    description,
    ogDescription: description,
    ogImage: story.content?.og_image?.filename,
    ogArticlePublishedTime: story?.first_published_at,
    ogArticleModifiedTime: story?.published_at,
    ogArticleAuthor: "zweikern",
  });

  story = unref(story);
  useHead({
    htmlAttrs: {
      lang: locale,
    },
    link: [
      {
        rel: "alternate",
        href: `${config.public.siteUrl}/${story.default_full_slug !== "home" ? utils.forceTrailingSlash(story.default_full_slug) : ""}`,
        hreflang: "de",
        title,
      },
      ...(story.translated_slugs || [])
        .filter((translation) => !!translation.name)
        .map((translation) => ({
          rel: "alternate",
          href: `${config.public.siteUrl}/${translation.lang}/${translation.path !== "home" ? utils.forceTrailingSlash(translation.path) : ""}`,
          hreflang: translation.lang,
          title: translation.name,
        })),
      ...(story.translated_slugs || [])
        .filter((translation) => !!translation.path && translation.name)
        .map((translation) => ({
          rel: "alternate",
          href: `${config.public.siteUrl}/${translation.lang}/${translation.path !== "home" ? utils.forceTrailingSlash(translation.path) : ""}`,
          hreflang: "x-default",
          title: translation.name,
        })),
      story.full_slug
        ? {
            rel: "canonical",
          href:
              config.public.siteUrl +
              utils.forceTrailingSlash(
                story.default_full_slug === "home" && story.lang === "de"
                  ? localePath("/")
                  : "/" + story.full_slug,
              ),
        }
        : {},
    ],
  });
  // const { showScrollToTopButton } = useScrollTop()

  return {
    // showScrollToTopButton
  };
};
